$.fn.piecesJointes = {
    initialize: function (urlGet) {
        $(function () {
            $('#add-attachment form').submit(function () {
                var form = $(this),
                    formData = new FormData(),
                    formParams = form.serializeArray();

                $.each(form.find('input[type="file"]'), function (i, tag) {
                    $.each($(tag)[0].files, function (i, file) {
                        formData.append(tag.name, file);
                    });
                });

                $.each(formParams, function (i, val) {
                    formData.append(val.name, val.value);
                });
                if ($(this).valid()) {
                    $("#addPieceJointeButtons").hide();
                    $("#addPieceJointeStatus").show();
                    var method = this.method;
                    var action = this.action;
                    setTimeout(function () {
                        $.ajax({
                            url: action,
                            type: method,
                            data: formData,
                            processData: false,
                            contentType: false,
                            success: function (result) {
                                $("#addPieceJointeButtons").show();
                                $("#addPieceJointeStatus").hide();
                                $('#add-attachment form').trigger("reset");
                                $('#add-attachment').foundation('close');
                                $.fn.piecesJointes.Load(urlGet);
                            },
                            error: function (a, b, c) {
                                $("#buaddPieceJointeButtonsttons").show();
                                $("#addPieceJointeStatus").hide();
                                $.each(JSON.parse(c), function (index, e) {
                                    $("span[data-valmsg-for='" + e.key + "']").text(e.value);
                                    $("span[data-valmsg-for='" + e.key + "']").removeClass("field-validation-valid");
                                    $("span[data-valmsg-for='" + e.key + "']").removeClass("field-validation-error");
                                    $("span[data-valmsg-for='" + e.key + "']").addClass("field-validation-error");
                                });

                            }
                        });
                    }, 100);
                }
                return false;
            });
        });
    },

    Load: function (url) {
        $.ajax({
            url: url,
            type: "GET",
            success: function (result) {
                $(".attachment-list").html("");
                $.each(result, function (index, e) {
                    $(".attachment-list").append('<li class="attachment"><span class="attachment__icon"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16">    <path id="a" d="M10.333 1v3.5h3.5l-3.5-3.5zM9.167 1H3.333c-.644 0-1.166.522-1.166 1.167v11.666c0 .645.522 1.167 1.166 1.167h9.334c.644 0 1.166-.522 1.166-1.167V5.667H9.167V1zm1.75 11.667H5.083a.583.583 0 0 1 0-1.167h5.834a.583.583 0 1 1 0 1.167zm0-4.084a.583.583 0 0 1 0 1.167H5.083a.583.583 0 0 1 0-1.167h5.834z"/></svg></span><p class="attachment__name"><a href="' + e.FileChemin + '" class="attachment__link" download>' + e.FileNom + '</a><span class="upper fs-14">' + e.FileNom + '</span></p><a href="' + e.FileChemin + '" class="attachment__button" download>Télécharger</a></li>');

                });
            },
            error: function (a, b, c) {
                $(".attachment-list").html("");
                $(".attachment-list").append('<li class="equipment"><span class="equipment__name">Erreur lors du chargement des équipements</span></li>');
            }
        });
    }
}
$.fn.commissions = {
    urlGetEquipements: "",
    urlGetPieceJointes: "",

    initialize: function (urlGetPieceJointes) {
        $.fn.commissions.urlGetPieceJointes = urlGetPieceJointes;

        $.fn.piecesJointes.initialize(urlGetPieceJointes);

        $(function () {
            Globalize.culture('fr-FR');
            $.validator.methods.date = function (value, element) {
                return this.optional(element)
                    || Globalize.parseDate(value, "dd/MM/yyyy", "fr-FR")
                    || Globalize.parseDate(value, "dd/MM/yyyy");
            };
            kendo.culture("fr-FR");
        });

        $.fn.commissions.LoadPieceJointes();


        $.validator.addMethod('date',
            function (value, element) {
                if (this.optional(element)) {
                    return true;
                }

                var ok = true;
                try {
                    var parts = value.split("/");
                    var dt = new Date(parseInt(parts[2], 10),
                        parseInt(parts[1], 10) - 1,
                        parseInt(parts[0], 10));
                }
                catch (err) {
                    ok = false;
                }
                return ok;
            });

        $('#pjUpload').change(function () {
            var i = $(this).prev('label').clone();
            var file = $('#pjUpload')[0].files[0].name;
            $('#pjNom').val(file);
        });
    },

    LoadPieceJointes: function () {
        $.fn.piecesJointes.Load($.fn.commissions.urlGetPieceJointes);
    }
};
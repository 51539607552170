$(function () {
    $(document).foundation();

    App.Kernel.bindComponents($('body'));

    $('.autocomplete-field .input__wrapper input').on('focus', function () {
        $(this).parent().parent().addClass('opened');
    });

    $('.autocomplete-field').on('focusout', function () {
        var that = this;
        setTimeout(function () {
            $(that).removeClass('opened')
        }, 200);
    });

    $('.autocomplete-field .autocomplete__item').on('click', function (e) {
        var root = $(this).parent().parent().parent();
        var text = $(this).text();
        var value = $(this).attr("value");
        $('input[type="text"]', root).val(text);
        $('input[type="hidden"]', root).val(value);
        $(root).removeClass('opened');
    });


});

$(document).ajaxComplete(function (event, request, settings) {
    // On re-bind les components a chaque call Ajax
    App.Kernel.bindComponents($("body"));
});
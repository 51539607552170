$.fn.equipements = {

    initialize: function (urlGet) {
        $(function () {
            $('#add-equipment form').submit(function () {
                var form = $(this),
                    formData = new FormData(),
                    formParams = form.serializeArray();

                $.each(form.find('input[type="file"]'), function (i, tag) {
                    $.each($(tag)[0].files, function (i, file) {
                        formData.append(tag.name, file);
                    });
                });

                $.each(formParams, function (i, val) {
                    formData.append(val.name, val.value);
                });

                if ($(this).valid()) {
                    $("#addEquipementPieceJointeButtons").hide();
                    $("#addEquipementPieceJointeStatus").show();
                    var method = this.method;
                    var action = this.action;
                    setTimeout(function () {
                    $.ajax({
                        url: action,
                        type: method,
                        data: formData,
                        processData: false,
                        contentType: false,
                        success: function (result) {
                            $("#buttoaddEquipementPieceJointeButtonsns").show();
                            $("#addEquipementPieceJointeStatus").hide();
                            $('#add-equipment form').trigger("reset");
                            $('#add-equipment').foundation('close');
                            $.fn.equipements.Load(urlGet);
                        },
                        error: function (a, b, c) {
                            $("#addEquipementPieceJointeButtons").show();
                            $("#addEquipementPieceJointeStatus").hide();
                            $.each(JSON.parse(c), function (index, e) {
                                $("span[data-valmsg-for='" + e.key + "']").text(e.value);
                                $("span[data-valmsg-for='" + e.key + "']").removeClass("field-validation-valid");
                                $("span[data-valmsg-for='" + e.key + "']").removeClass("field-validation-error");
                                $("span[data-valmsg-for='" + e.key + "']").addClass("field-validation-error");
                            });

                        }
                        });
                    }, 100);
                }
                return false;
            });
        });
        $(function () {
            $('#add-equipment-to-intervention form').submit(function () {
                var form = $(this),
                    formData = new FormData(),
                    formParams = form.serializeArray();

                $.each(formParams, function (i, val) {
                    formData.append(val.name, val.value);
                });

                if ($(this).valid()) {
                    $.ajax({
                        url: this.action,
                        type: this.method,
                        data: formData,
                        processData: false,
                        contentType: false,
                        success: function (result) {
                            $('#add-equipment-to-intervention form').trigger("reset");
                            $('#add-equipment-to-intervention').foundation('close');
                            $.fn.equipements.Load(urlGet);
                        },
                        error: function (a, b, c) {
                            $.each(JSON.parse(c), function (index, e) {
                                $("span[data-valmsg-for='" + e.key + "']").text(e.value);
                                $("span[data-valmsg-for='" + e.key + "']").removeClass("field-validation-valid");
                                $("span[data-valmsg-for='" + e.key + "']").removeClass("field-validation-error");
                                $("span[data-valmsg-for='" + e.key + "']").addClass("field-validation-error");
                            });

                        }
                    });
                }
                return false;
            });
        });

        $(function () {
            $('#modifyEquipmentForm').submit(function () {
                if ($(this).valid()) {
                    $.ajax({
                        url: this.action,
                        type: this.method,
                        data: $(this).serialize(),
                        success: function (data, textStatus, jqXHR) {
                            $('div#modify-equipment').foundation('close');

                            $.fn.contrats.LoadEquipements();
                        },
                        error: function (a, b, c) {
                            $.each(JSON.parse(c), function (index, e) {
                                $("span[data-valmsg-for='" + e.key + "']").text(e.value);
                                $("span[data-valmsg-for='" + e.key + "']").removeClass("field-validation-valid");
                                $("span[data-valmsg-for='" + e.key + "']").removeClass("field-validation-error");
                                $("span[data-valmsg-for='" + e.key + "']").addClass("field-validation-error");
                            });

                        }
                    });
                }
                return false;
            });
        });
    },

    Load: function (url) {
        $.ajax({
            url: url,
            type: "GET",
            success: function (result) {
                $("div.block.contact.providers div#equipmentsContainer").html(result);

            },
            error: function (a, b, c) {
                $(".equipment-list").html("");
                $(".equipment-list").append('<li class="equipment"><span class="equipment__name">Erreur lors du chargement des équipements</span></li>');
            }
        });
    },

    ModifyEquipement: function (url, id) {
        $.ajax({
            url: url,
            type: "GET",
            data: { equipementId:id},
            success: function (result) {
                $("div#modify-equipment").html(result);
                $('div#modify-equipment').foundation('open');
                $("div#modify-equipment #EquiDateMiseEnService").kendoDatePicker();
                $("div#modify-equipment #EquiDateMiseHorsService").kendoDatePicker();
            },
            error: function (a, b, c) {
                $.each(JSON.parse(c), function (index, e) {
                    $("span[data-valmsg-for='" + e.key + "']").text(e.value);
                    $("span[data-valmsg-for='" + e.key + "']").removeClass("field-validation-valid");
                    $("span[data-valmsg-for='" + e.key + "']").removeClass("field-validation-error");
                    $("span[data-valmsg-for='" + e.key + "']").addClass("field-validation-error");
                });

            }
        });
    }
};
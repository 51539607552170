$.fn.interventions = {
    urlGetEquipements: "",
    urlGetPieceJointes: "",

    initialize: function (isCreation, urlGetEquipements, urlGetPieceJointes) {
        $.fn.interventions.urlGetEquipements = urlGetEquipements;
        $.fn.interventions.urlGetPieceJointes = urlGetPieceJointes;

        $.fn.equipements.initialize(urlGetEquipements);
        $.fn.piecesJointes.initialize(urlGetPieceJointes);

        $(function () {
            Globalize.culture('fr-FR');
            $.validator.methods.date = function (value, element) {
                return this.optional(element)
                    || Globalize.parseDate(value, "dd/MM/yyyy", "fr-FR")
                    || Globalize.parseDate(value, "dd/MM/yyyy");
            };
            kendo.culture("fr-FR");
        });

        if (!isCreation) {
            $.fn.interventions.LoadEquipements();
            $.fn.interventions.LoadPieceJointes();
        }

        $('#equipmentFile').change(function () {
            var i = $(this).prev('label').clone();
            var file = $('#equipmentFile')[0].files[0].name;
            $('#equipmentFileName').text(file);
        });

        $.validator.addMethod('date',
            function (value, element) {
                if (this.optional(element)) {
                    return true;
                }

                var ok = true;
                try {
                    var parts = value.split("/");
                    var dt = new Date(parseInt(parts[2], 10),
                        parseInt(parts[1], 10) - 1,
                        parseInt(parts[0], 10));
                }
                catch (err) {
                    ok = false;
                }
                return ok;
            });

        $('#pjUpload').change(function () {
            var i = $(this).prev('label').clone();
            var file = $('#pjUpload')[0].files[0].name;
            $('#pjNom').val(file);
        });

        $('#EquiPjUpload').change(function () {
            var i = $(this).prev('label').clone();
            var file = $('#EquiPjUpload')[0].files[0].name;
            $('#EquiPjNom').val(file);
        });


        $(function () {
            $('#add-equipment form').submit(function () {
                var form = $(this),
                    formData = new FormData(),
                    formParams = form.serializeArray();

                $.each(form.find('input[type="file"]'), function (i, tag) {
                    $.each($(tag)[0].files, function (i, file) {
                        formData.append(tag.name, file);
                    });
                });

                $.each(formParams, function (i, val) {
                    formData.append(val.name, val.value);
                });

                if ($(this).valid()) {
                    $.ajax({
                        url: this.action,
                        type: this.method,
                        data: formData,
                        processData: false,
                        contentType: false,
                        success: function (result) {
                            $('#add-equipment form').trigger("reset");
                            $('#add-equipment').foundation('close');
                            $.fn.interventions.LoadEquipements();
                        },
                        error: function (a, b, c) {
                            $.each(JSON.parse(c), function (index, e) {
                                $("span[data-valmsg-for='" + e.key + "']").text(e.value);
                                $("span[data-valmsg-for='" + e.key + "']").removeClass("field-validation-valid");
                                $("span[data-valmsg-for='" + e.key + "']").removeClass("field-validation-error");
                                $("span[data-valmsg-for='" + e.key + "']").addClass("field-validation-error");
                            });

                        }
                    });
                }
                return false;
            });
        });

        $(function () {
            $('#detachEquipmentForm').submit(function () {
                if ($(this).valid()) {
                    $.ajax({
                        url: this.action,
                        type: this.method,
                        data: $(this).serialize(),
                        success: function (data, textStatus, jqXHR) {
                            $('#detach-equipment').foundation('close');

                            $.fn.interventions.LoadEquipements();
                        },
                        error: function (a, b, c) {
                            $.each(JSON.parse(c), function (index, e) {
                                $("span[data-valmsg-for='" + e.key + "']").text(e.value);
                                $("span[data-valmsg-for='" + e.key + "']").removeClass("field-validation-valid");
                                $("span[data-valmsg-for='" + e.key + "']").removeClass("field-validation-error");
                                $("span[data-valmsg-for='" + e.key + "']").addClass("field-validation-error");
                            });

                        }
                    });
                }
                return false;
            });
        });

        $("#createNew").click(function (event) {
            $("input[name=IsCreateNewIntervention]").val(true);
            $('form#submitIntervention').submit();
            $('#createNewIntervention').foundation('close');
        });

        $("#notCreateNew").click(function (event) {
            $("input[name=IsCreateNewIntervention]").val(false);
            $('form#submitIntervention').submit();
            $('#createNewIntervention').foundation('close');
        });
    },

    LoadEquipements: function () {
        $.fn.equipements.Load($.fn.interventions.urlGetEquipements);
    },

    LoadPieceJointes: function () {
        $.fn.piecesJointes.Load($.fn.interventions.urlGetPieceJointes);
    }
};
$.fn.contrats = {
    urlGetFournisseurByPrestation: "",
    urlGetContratRattache: "",
    urlGetEquipements: "",
    urlGetPieceJointes: "",
    urlGetFournisseurContacts: "",
    idEtablissement:"",

    initialize: function (idEtablissement, urlGetFournisseurByPrestation, urlGetEquipements, urlGetPieceJointes, urlGetFournisseurContacts, urlGetContratRattache) {
        $.fn.contrats.urlGetFournisseurByPrestation = urlGetFournisseurByPrestation;
        $.fn.contrats.urlGetEquipements = urlGetEquipements;
        $.fn.contrats.urlGetPieceJointes = urlGetPieceJointes;
        $.fn.contrats.idEtablissement = idEtablissement;
        $.fn.contrats.urlGetFournisseurContacts = urlGetFournisseurContacts;
        $.fn.contrats.urlGetContratRattache = urlGetContratRattache; 
        

        $.fn.equipements.initialize(urlGetEquipements);
        $.fn.piecesJointes.initialize(urlGetPieceJointes);

        $(function () {
            Globalize.culture('fr-FR');
            $.validator.methods.date = function (value, element) {
                return this.optional(element)
                    || Globalize.parseDate(value, "dd/MM/yyyy", "fr-FR")
                    || Globalize.parseDate(value, "dd/MM/yyyy");
            };
            kendo.culture("fr-FR");
        });

        $.fn.contrats.reloadFournisseur(false,"");

        $.fn.contrats.LoadEquipements();
        $.fn.contrats.LoadPieceJointes();

        $('#equipmentFile').change(function () {
            var i = $(this).prev('label').clone();
            var file = $('#equipmentFile')[0].files[0].name;
            $('#equipmentFileName').text(file);
        });

        $.validator.addMethod('date',
            function (value, element) {
                if (this.optional(element)) {
                    return true;
                }

                var ok = true;
                try {
                    var parts = value.split("/");
                    var dt = new Date(parseInt(parts[2], 10),
                        parseInt(parts[1], 10) - 1,
                        parseInt(parts[0], 10));
                }
                catch (err) {
                    ok = false;
                }
                return ok;
            });

        $(function () {
            $('#formCreateFournisseur').submit(function () {
                if ($(this).valid()) {
                    $.ajax({
                        url: this.action,
                        type: this.method,
                        data: $(this).serialize(),
                        success: function (data, textStatus, jqXHR) {
                            $('#formCreateFournisseur').trigger("reset");

                            $('#add-provider').foundation('close');
                            $.fn.contrats.reloadFournisseur(false, jqXHR.statusText);
                        },
                        error: function (a, b, c) {
                            $.each(JSON.parse(c), function (index, e) {
                                $("span[data-valmsg-for='" + e.key + "']").text(e.value);
                                $("span[data-valmsg-for='" + e.key + "']").removeClass("field-validation-valid");
                                $("span[data-valmsg-for='" + e.key + "']").removeClass("field-validation-error");
                                $("span[data-valmsg-for='" + e.key + "']").addClass("field-validation-error");
                            });

                        }
                    });
                }
                return false;
            });
        });

        $(function () {
            $('#detachEquipmentForm').submit(function () {
                if ($(this).valid()) {
                    $.ajax({
                        url: this.action,
                        type: this.method,
                        data: $(this).serialize(),
                        success: function (data, textStatus, jqXHR) {
                            $('#detach-equipment').foundation('close');

                            $.fn.contrats.LoadEquipements();
                        },
                        error: function (a, b, c) {
                            $.each(JSON.parse(c), function (index, e) {
                                $("span[data-valmsg-for='" + e.key + "']").text(e.value);
                                $("span[data-valmsg-for='" + e.key + "']").removeClass("field-validation-valid");
                                $("span[data-valmsg-for='" + e.key + "']").removeClass("field-validation-error");
                                $("span[data-valmsg-for='" + e.key + "']").addClass("field-validation-error");
                            });

                        }
                    });
                }
                return false;
            });
        });

        $('#pjUpload').change(function () {
            var i = $(this).prev('label').clone();
            var file = $('#pjUpload')[0].files[0].name;
            $('#pjNom').val(file);
        });

        $('#AddEquiPjUpload1').change(function () {
            $.fn.contrats.AddPieceJointe();
        });

        $("#ImmeFournisseurId").change(function () {
            if ($('#ImmeFournisseurId').val() !== undefined && $('#ImmeFournisseurId').val() !== null && $('#ImmeFournisseurId').val() !== "") {
                $('#updateContacts').foundation('open');
            }
        });


        $("#update").click(function (event) {
            // get fournisseur contacts
            $.fn.contrats.reloadContacts(false);
            
            $('#updateContacts').foundation('close');
        });

        $("#notUpdate").click(function (event) {

            $('#updateContacts').foundation('close');
        });
    },
    AddPieceJointe: function () {
        var cnt = $('input.ajouterEquipementPieceJointe[type=file][name=Files]').length;
        var file = $('#AddEquiPjUpload' + cnt)[0].files[0].name;
        foo = $('#AddEquiPjUpload' + cnt)[0].files;
        $('#AddEquiPjNom' + cnt).val(file);



        var input = '<input id="AddEquiPjUpload' + (cnt + 1) + '" type="file" class="input-file ajouterEquipementPieceJointe" accept=".pdf,.jpg,.doc" name="Files">';
        $("div#ajouterEquipementPieceJointe.file-field__wrapper").append(input);
        $('#AddEquiPjUpload' + (cnt + 1)).change(function () {
            $.fn.contrats.AddPieceJointe();
        });

        var textBox = '<input type="text" name="nom" id="AddEquiPjNom' + (cnt + 1) + '" class="field__input" readonly />';
        $("div#ajouterPieceJointeFilesName").append(textBox);

        $("#ajouterEquipementPieceJointeLabel").attr('for', "AddEquiPjUpload" + (cnt + 1));
    },

    reloadContacts: function (isAsync) {
        if ($('#ImmeFournisseurId').val() === undefined || $('#ImmeFournisseurId').val() === null || $('#ImmeFournisseurId').val() === "") {
            $("#ContContact").val("");
            $("#ContContactTel").val("");
            $("#ContContactEmail").val("");
            $("#ContContactEmail").attr("href", "mailto:" + "");

            $("#ContTechnique").val("");
            $("#ContTechniqueTel").val("");
            $("#ContTechniqueEmail").val("");
            $("#ContTechniqueEmail").attr("href", "mailto:" + "");
            return;
        }
        var data = {
            idFournisseur: $('#ImmeFournisseurId').val()
        };

        $.ajax({
            url: $.fn.contrats.urlGetFournisseurContacts,
            type: 'GET',
            data: data,
            async: isAsync,
            success: function (result, statut) {
                $("#ContContact").val(result.Utilisateur.Nom);
                $("#ContContactTel").val(result.Utilisateur.Telephone);
                $("#ContContactEmail").val(result.Utilisateur.Email);
                $("#ContContactEmail").attr("href", "mailto:" + result.Utilisateur.Email);

                $("#ContTechnique").val(result.Technique.Nom);
                $("#ContTechniqueTel").val(result.Technique.Telephone);
                $("#ContTechniqueEmail").val(result.Technique.Email);
                $("#ContTechniqueEmail").attr("href", "mailto:" + result.Technique.Email);
            },
            error: function (resultat, statut, erreur) {
            }
        });
    },


    filterFournisseurs: function () {
        return { idEtablissement: $.fn.contrats.idEtablissement, idPrestation: $('#ImmePrestationID').data("kendoComboBox").value() };
    },

    reloadFournisseurField(isAsync, currentId, nomFieldFournisseur) {
        $.ajax({
            url: $.fn.contrats.urlGetFournisseurByPrestation,
            type: 'GET',
            data: $.fn.contrats.filterFournisseurs(),
            async: isAsync,
            success: function (code_html, statut) {
                $("ul#Liste" + nomFieldFournisseur).empty();
                $('#Imme' + nomFieldFournisseur+ 'Id').val("");
                $('#Imme' + nomFieldFournisseur + 'IdDisplay').val("");
                $.each(code_html, function (index, value) {
                    const $newLi = $("<li class='autocomplete__item' value='" + value.Value + "' text='" + value.Text + "'>" + value.Text + "</li>");
                    if (currentId === value.Value) {
                        $('#Imme' + nomFieldFournisseur +  'IdDisplay').val(value.Text);
                        $('#Imme' + nomFieldFournisseur + 'Id').val(value.Value);
                    }
                    $newLi.on('click', function (e) {
                        var root = $(this).parent().parent().parent();
                        var text = $(this).text();
                        var value = $(this).attr("value");
                        $('input[type="text"]', root).val(text);
                        $('input[type="hidden"]', root).val(value).trigger('change');
                        $(root).removeClass('opened');
                    });
                    $("ul#Liste" + nomFieldFournisseur).append($newLi);
                });
                $('#Imme' + nomFieldFournisseur + 'Id').trigger('change');
            },
            error: function (resultat, statut, erreur) {
                $("ul#Liste" + nomFieldFournisseur).html("");
                $("ul#Liste" + nomFieldFournisseur).append('<li class="equipment"><span class="equipment__name">Erreur lors du chargement des fournisseurs</span></li>');
            }
        });
    },

    reloadFournisseur: function (isAsync, currentId) {
        $.fn.contrats.reloadFournisseurField(isAsync, currentId, "Fournisseur");
        $.fn.contrats.reloadFournisseurField(isAsync, currentId, "FournisseurRattache");

        var multiselect = $("#Prestations").data("kendoMultiSelect");
        multiselect.value([$('#ImmePrestationID').data("kendoComboBox").value()]);
    },

    filterContratRattache: function () {
        return { id: $('#ImmeContratRattacheId').data("kendoComboBox").value() };
    },

    recupererContratRattache: function (isAsync) {

        $.ajax({
            url: $.fn.contrats.urlGetContratRattache,
            type: 'GET',
            data: $.fn.contrats.filterContratRattache(),
            async: isAsync,
            success: function (result, statut) {
                $('#ImmePrestationID').data("kendoComboBox").value(result.ImmePrestationID);
            }
        });

        var multiselect = $("#Prestations").data("kendoMultiSelect");
        multiselect.value([$('#ImmePrestationID').data("kendoComboBox").value()]);
    },

    onSelectPresta: function (e) {
        $.fn.contrats.reloadFournisseur(true,"");
    },

    onSelectContratRattache: function (e) {
        $.fn.contrats.recupererContratRattache(false);
        $.fn.contrats.reloadFournisseur(true, "");
    },

    LoadEquipements: function () {
        $.fn.equipements.Load($.fn.contrats.urlGetEquipements);
    },

    LoadPieceJointes: function () {
        $.fn.piecesJointes.Load($.fn.contrats.urlGetPieceJointes);
    }
};
$.fn.dashboard = {
    urlGetFournisseurByPrestation: "",
    urlGetFournisseurContacts: "",
    idEtablissement: "",

    initialize: function (idEtablissement, urlGetFournisseurByPrestation, urlGetFournisseurContacts) {
        $.fn.dashboard.urlGetFournisseurByPrestation = urlGetFournisseurByPrestation;
        $.fn.dashboard.urlGetFournisseurContacts = urlGetFournisseurContacts;
        $.fn.dashboard.idEtablissement = idEtablissement;
        $(function () {
            $("#SelectPresta").change(function () {
                $.fn.dashboard.reloadFournisseur(true, ($("#SelectPresta").val() ===""));
            });

            $("#SelectFournisseur").change(function () {
                
                $.fn.dashboard.reloadContacts(true);
            });

            $('#formBesoinAide').submit(function () {
                if ($(this).valid()) {
                    $.ajax({
                        url: this.action,
                        type: this.method,
                        data: $(this).serialize(),
                        success: function (data, textStatus) {
                            $('#formBesoinAide').trigger("reset");
                            $('#needHelpModal').foundation('close');
                        },
                        error: function (a, b, c) {
                            $.each(JSON.parse(c), function (index, e) {
                                $("span[data-valmsg-for='" + e.key + "']").text(e.value);
                                $("span[data-valmsg-for='" + e.key + "']").removeClass("field-validation-valid");
                                $("span[data-valmsg-for='" + e.key + "']").removeClass("field-validation-error");
                                $("span[data-valmsg-for='" + e.key + "']").addClass("field-validation-error");
                            });

                        }
                    });
                }
                return false;
            });
        });
        $.fn.dashboard.reloadFournisseur(false);
        $.fn.dashboard.reloadContacts(false);
    },

    onSelectPresta: function () {
        $.ajax({
            url: $.fn.dashboard.urlGetFournisseurByPrestation,
            data: {
                idEtablissement: $.fn.dashboard.idEtablissement,
                idPrestation: $("#PrestationId").val()
            },
            success: function (datas) {
                $("#FournisseurId").data("kendoComboBox").setDataSource(datas);
                $("#FournisseurId").data("kendoComboBox").select(0);
                $.fn.dashboard.reloadContacts(true);
            }
        });
    },

    onSelectFournisseur: function () {
        $.fn.dashboard.reloadContacts(true);
    },

    reloadFournisseur: function (isAsync, getAll) {
        var data = {
            idEtablissement: $.fn.dashboard.idEtablissement,
            idPrestation: getAll?null:$('#SelectPresta').val()
        };

        $.ajax({
            url: $.fn.dashboard.urlGetFournisseurByPrestation,
            type: 'GET',
            data: data,
            async: isAsync,
            success: function (code_html, statut) {
                $("#SelectFournisseur").empty();
                $.each(code_html, function (index, value) {
                    const $newOption = $("<option value='" + value.Value + "'>" + value.Text + "</option>");
                    $("#SelectFournisseur").append($newOption);
                });
                $.fn.dashboard.reloadContacts();
            },
            error: function (resultat, statut, erreur) {
                const $newOption = $("<option value='0'>Erreur lors du chargement des fournisseurs</option>");
                $("#SelectFournisseur").append($newOption);
            }
        });
    },

    clearContact: function (id) {
        $("#" + id + " .name").text("");
        $("#" + id + " .title").text("");
        $("#" + id + " .tel").text("");
        $("#" + id + " .mail").text("");
        $("#" + id + " .mail").attr("href", "");
    },

    setContact: function (id, title, contact) {
        $("#" + id + " .title").text(title);
        $("#" + id + " .name").text(contact.Nom);
        $("#" + id + " .tel").text(contact.Telephone);
        $("#" + id + " .mail").text(contact.Email);
        $("#" + id + " .mail").attr("href", "mailto:" + contact.Email);
    },

    reloadContacts: function (isAsync) {
        var data = {
            idFournisseur: $('#FournisseurId').val(),
            idEtablissement: $.fn.dashboard.idEtablissement,
            idPrestation: $("#PrestationId").val() === "" ? null : $("#PrestationId").val()
        };
        $.ajax({
            url: $.fn.dashboard.urlGetFournisseurContacts,
            type: 'GET',
            data: data,
            async: isAsync,
            success: function (result, statut) {
                $("#FournisseurContact .no-data").hide();
                $("#FournisseurContacts").html(result);
            },
            error: function (resultat, statut, erreur) {
                $("#FournisseurContact .no-data").show();

                $.fn.dashboard.clearContact("contactTechnique");
                $.fn.dashboard.clearContact("contactUtilisateur");
            }
        });
    },

    EnSavoirPlus: function (date, destinataire, objet, msg) {
        $("#enSavoirPlusDate").val(date);
        $("#enSavoirPlusDestinataire").val(destinataire);
        $("#enSavoirPlusObjet").val(objet);
        $("#enSavoirPlusMessage").html(msg);
        $('#enSavoirPlusModal').foundation('open');
    },

    Masquer: function(url,guid) {
        $.ajax({
            url: url,
            type: "GET",
            data: { id: guid },
            success: function (data, textStatus) {
                setTimeout(function () {
                    $("#notificationContent div.block-line#" + guid).remove();
                    if ($("#notificationContent div.block-line").length < 1) {
                        $("#notificationContent div#FOO").show();
                    }
                }, 1000);
            },
            error: function (a, b, c) {
            }
        });
    }
};
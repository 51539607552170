$.fn.etablissement = {

    urlChangeEtablissement: "Dashboard/UpdateEtablissementSelectionne",

    initialize: function () {
        $.fn.dashboard.urlChangeEtablissement;
    },

    onSelectEtablissement: function () {

        $.ajax({
            url: $.fn.etablissement.urlChangeEtablissement,
            data: {
                idEtablissement: $("#ImmeEtablissementId").val()
            },
            success: function (datas) {
                window.location.reload();
            }
        });
    },

};
window.$ = $;
window.jQuery = $;
window.jquery = $;

import App from '6tm-components';
window.App = App;

// Init Foundation
require('foundation-sites');
require('what-input');

Foundation.addToJquery($);
import 'foundation-sites/js/foundation.core.js';
import 'foundation-sites/js/foundation.reveal.js';

import '@progress/kendo-ui/';
import '@progress/kendo-ui/js/kendo.grid';

import '@progress/kendo-ui/js/kendo.timezones.js';
import '@progress/kendo-ui/js/kendo.aspnetmvc.js';
import '@progress/kendo-ui/js/cultures/kendo.culture.fr-FR.js';
import '@progress/kendo-ui/js/messages/kendo.messages.fr-FR.js';

// Import Styles
import '@progress/kendo-ui/css/web/kendo.common.css';
import '@progress/kendo-ui/css/web/kendo.material.css';
import './scss/global.scss';

// Global scripts
import './js/main.js';
import './js/components/6tm-searchbox.js';
import 'jquery-validation-unobtrusive/dist/jquery.validate.unobtrusive.min.js';
import './js/pages/Contrats.js';
import './js/pages/Equipements.js';
import './js/pages/Etablissement.js';
import './js/pages/PiecesJointes.js';
import './js/pages/Interventions.js';
import './js/pages/Commissions.js';
import './js/pages/Dashboard.js';

import Globalize from '6tm-globalize';
import '6tm-globalize/src/cultures/fr-FR';
window.Globalize = Globalize;